
import * as storyblokRuntime$JrSsRfs9Gy from '/opt/build/repo/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as cloudimageRuntime$12Iexni3B9 from '/opt/build/repo/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/cloudimage'
import * as icsRuntime$AGq3FcIqGv from '/opt/build/repo/providers/ics.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "storyblok",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$JrSsRfs9Gy, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['cloudimage']: { provider: cloudimageRuntime$12Iexni3B9, defaults: {"token":"adhgfzvyfq","baseURL":"v7/https://id-cs.com"} },
  ['ics']: { provider: icsRuntime$AGq3FcIqGv, defaults: undefined }
}
        